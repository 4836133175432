<template>
  <div>
      
      <!-- <h2 class="bgk-blue py-5 text-white font-weight-bolder">{{$tc('eresempresa.multi.title')}}</h2> -->
      <!-- <div class="px-6">
        <p class="py-5 px-6 font-weight-bold desc-multi">{{$tc('eresempresa.multi.desc')}}</p>
      </div> -->
      <div class="mb-5">
        <b-button class="px-5 py-2 rounded-pill" variant="black" href="https://cloud.edu.mindhubweb.com/empresas">{{
            $t("eresempresa.button4")
        }}</b-button>
      </div>
      <div v-for="(value,key) in immersions" :key="key" class="px-6">
          <div class="bgk-black py-5">
              <p class="h1 text-white">{{key}}</p>
          </div>
          <b-container>
              <b-row class="py-4 px-6 immersions" align-h="center">
                  <b-col cols="12" lg="6" class="immersion p-3" v-for="(immersion,index) in value" :key="index">
                      <div class="line"></div>
                      <div class="immersion-card bg-light"> 
                          <h3 class="font-weight-bolder" v-html="fancyText2(immersion.title)"></h3>
                          <p><strong>{{$tc('eresempresa.multi.duration')}}:</strong> {{immersion.totalHours}} {{$tc('eresempresa.multi.hours')}}</p>
                          <p><strong>{{$tc('eresempresa.multi.modality')}}:</strong> {{$tc(`modalities.${immersion.modality}`).toUpperCase()}} / {{fancyText(immersion.courseType)}}</p>
                          <p><strong>{{$tc('eresempresa.multi.date')}}:</strong> {{fancyDate(immersion.initDate)}}</p>
                      </div>
                  </b-col>
              </b-row>
          </b-container>
      </div>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n';

export default {
    name: 'Multiempresas',
    data(){
        return {
            bootcamps: null
        }
    },
    computed: {
        immersions(){
            let result = {}
            if(this.bootcamps != null && this.bootcamps.length != 0){
                let immersions = this.bootcamps
                                .filter((boot) => 
                                    boot.title.toLowerCase().includes('immersion') 
                                    && Date.now() <= boot.initDate
                                )

                
                immersions.forEach(immersion => {
                    let initDate = new Date(immersion.initDate);
                    let month = this.monthString( initDate.getMonth());

                    if(result[month] == undefined){
                        result[month] = []
                    }

                    result[month].push(immersion)
                    
                })
            }
            
            return result
        },
        lang() {
          return i18n.locale;
        },
    },
    methods: {
        getBootcamps() {
             
             
            const strapiUrl = process.env.VUE_APP_STRAPI_URL;
            const apiKey = process.env.VUE_APP_API_KEY;
            fetch(`${strapiUrl}/api/bootcamps`, {
                headers: {
                'Authorization': `Bearer ${apiKey}`,
                }
            })
                .then((res) => res.json())
                .then((json) => {
                    if(!json[0].publishedAt){
                        return null
                    }
                    this.bootcamps = json
                });
        },
        fancyDate(ms){
            let initDate = new Date(ms);
            return initDate.getDate() + "/" + (initDate.getMonth() + 1) + '/' + initDate.getFullYear()
        },
        fancyText(str) {
            return str.split('_').map(w => w.toUpperCase()).join(" ")
        },
        fancyText2(str) {
            if(str.includes('advanced')){
                return str.split('_').map(w => {
                    if(w == "analytics"){
                        return "<br>" + w[0].toUpperCase() + w.slice(1)
                    }
                    else{
                        return w[0].toUpperCase() + w.slice(1)
                    }  
                }).join(" ")
            }else {
                    return str.split('_').map(w => {
                        if(w == "immersion"){
                            return "<br>" + w[0].toUpperCase() + w.slice(1)
                        }else if(w == "mern"){
                            return w.toUpperCase()
                        }else if(w == ".net"){
                            return w[0] + w[1].toUpperCase() + w.slice(2)
                        }
                        else{
                            return w[0].toUpperCase() + w.slice(1)
                        }  
                    }).join(" ")
            }
            
        },
        monthString(value){
            switch(value){
                case 0:
                    return this.lang == 'es' ? 'ENERO' : 'JANUARY'
                case 1:
                    return this.lang == 'es' ? 'FEBRERO' : 'FEBRUARY'
                case 2:
                    return this.lang == 'es' ? 'MARZO' : 'MARCH'
                case 3:
                    return this.lang == 'es' ? 'ABRIL' : 'APRIL'
                case 4:
                    return this.lang == 'es' ? 'MAYO' : 'MAY'
                case 5:
                    return this.lang == 'es' ? 'JUNIO' : 'JUNE'
                case 6:
                    return this.lang == 'es' ? 'JULIO' : 'JULY'
                case 7:
                    return this.lang == 'es' ? 'AGOSTO' : 'AUGUST'
                case 8:
                    return this.lang == 'es' ? 'SEPTIEMBRE' : 'SEPTEMBER'
                case 9:
                    return this.lang == 'es' ? 'OCTUBRE' : 'OCTOBER'
                case 10:
                    return this.lang == 'es' ? 'NOVIEMBRE' : 'NOVEMBER'
                case 11:
                    return this.lang == 'es' ? 'DECIEMBRE' : 'DECEMBER'

            }
        }
    },
    beforeMount() {
        this.getBootcamps();
    },
}
</script>

<style scoped>
.immersion-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 2em;
}

.line{
    height: 20px;
}

.immersions .immersion:nth-child(3n+1) .line{
    background-color: #ff00ff;
}

.immersions .immersion:nth-child(3n+2) .line{
    background-color: #0000ff;
}

.immersions .immersion:nth-child(3n+0) .line{
    background-color: #baff00;
}

.immersion-card * {
    margin: .5em 0;
}

.immersion-card button{
    font-size: .7rem;
}
@media (min-width: 968px){
    .desc-multi{
        margin: 0 190px;
    }
}
</style>