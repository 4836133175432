<template>
  <div class="capacitar p-5" id="capacitar">
    <h2 class="text-pink">{{ $t("eresempresa.t5") }}</h2>
    <div v-for="(program, index) in programs" :key="index" class="capacitar-item" @click="toggleProgram(index)">
      <div class="conteiner-title px-2">
        <h3 v-html="program.title" class="title"></h3>
        <span :class="{'triangle-down': isProgramVisible(index), 'triangle-right': !isProgramVisible(index)}"></span>
      </div>
      <div class="items-container" v-show="isProgramVisible(index)">
        <div v-for="(item, itemIndex) in program.items" :key="itemIndex" class="item">
          <div class="main-content">
            <div class="label">{{ item.label }}</div>
            <div class="hours">{{ item.hours }}</div>
          </div>
          <div v-if="item.details" class="details">{{ item.details }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "Capacitar",
  computed: {
    lang() {
      return i18n.locale;
    },
    styleObj() {
      return {
        backgroundImage:
          "url(" +
          require(this.lang == "es"
            ? "../../assets/empresas/bannerchicotittle.jpg"
            : "../../assets/empresas/bannerchicotittleen.jpg") +
          ")",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      };
    },
  },
  data() {
    return {
      visiblePrograms: [],
      programs: [
        {
          title: this.$t('eresempresa.program1.title'),
          items: this.$t('eresempresa.program1.items')
        },
        {
          title: this.$t('eresempresa.program2.title'),
          items: this.$t('eresempresa.program2.items')
        },
        {
          title: this.$t('eresempresa.program3.title'),
          items: this.$t('eresempresa.program3.items')
        },
        {
          title: this.$t('eresempresa.program4.title'),
          items: this.$t('eresempresa.program4.items')
        }
      ]
    };
  },
  methods: {
    toggleProgram(programNumber) {
      if (this.visiblePrograms.includes(programNumber)) {
        this.visiblePrograms = this.visiblePrograms.filter(num => num !== programNumber);
      } else {
        this.visiblePrograms.push(programNumber);
      }
    },
    isProgramVisible(programNumber) {
      return this.visiblePrograms.includes(programNumber);
    }
  }
};
</script>

<style scoped>
.capacitar {
  padding: 2em 1%;
}

.capacitar h2 {
  font-weight: bolder;
}

.capacitar > p {
  margin: 1.5rem auto;
  padding: 0 1rem;
}

.capacitar-card {
  background-color: #f7f7f7;
  height: 100%;
  box-sizing: border-box;
  border-width: 20px 0 0 0;
  border-style: solid;
  border-color: #0000ff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  transition: border-color 0.3s; /* Añade una transición para suavizar el cambio */
}

.capacitar-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.conteiner-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.triangle-right::after {
  content: '\25B6'; /* Triángulo apuntando a la derecha */
}
.triangle-down::after {
  content: '\25BC'; /* Triángulo apuntando hacia abajo */
}

.capacitar-card .info {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.capacitar-card h3 {
  font-weight: bolder;
}

.capacitar-card p {
  font-size: 0.8rem !important;
}

.info p{
  font-weight: bold;
  font-size: 1rem !important;
}

.newtag{
  position: absolute;
  top: -20px;
  right: 0;
  background: #0000ff;
  color: #baff00;
  font-weight: bold;
  border-radius: 0px 0 0 30px;
  padding: 5px 20px 10px 40px;
}

.newtag p{
  margin: 0;
}

.preText{
  font-family: 'Poppins';
  white-space: pre-wrap;
}

.col-banner{
  width: 100%;
  height: 100%;
  background-image: url('../../assets/empresas/chico.jpg');
  background-size: cover;
  background-position: center;
}

.col-banner2{
  width: 100%;
  height: 100%;
  background-image: url('../../assets/empresas/alumnas.jpg');
  background-size: cover;
  background-position: center;
}

.row>div{
  margin: 5px 0;
}

.items-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra los items horizontalmente */
}

.item {
  width: 100%; /* Asegura que cada item ocupe todo el ancho del contenedor */
  margin-bottom: 1em;
  background-color: #A0D822;
/* Rectangle 71 */

/* position: absolute;
width: 708px;
height: 60px; */

background: rgba(160, 216, 34, 0.17);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;

}

.main-content {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Asegura que el contenido principal ocupe todo el ancho del item */
}

.label {
  font-weight: bold;
}

.hours {
  margin-left: auto;
}

.details {
  /* margin-left: 20px; */
  font-style: italic;
  text-align: left;
}

.conteiner-title {
  height: 15%;
  display:flex;
  align-items: center;
  /* Rectangle 69 */

/* position: absolute;
width: 759px;
height: 68px; */

background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
margin-bottom: 0.5rem;

}

.conteiner-title span {
  color: #BAFF43;
}

.title {
  text-align: center;
  margin-bottom: 1em;
}
</style>