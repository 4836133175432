<template>
  <div class="faq p-5" id="faq">
    <h2 class="faq-title">{{ $t("eresempresa.t6") }}</h2>
    <div v-for="(question, index) in questions" :key="index" class="faq-item" @click="toggleAnswer(index)">
      <div class="faq-question">
        <h4>{{ $t(`eresempresa.faqs.question[${index}]`) }}</h4>
        <span :class="{'triangle-down': isAnswerVisible(index), 'triangle-right': !isAnswerVisible(index)}"></span>
      </div>
      <p v-show="isAnswerVisible(index)" class="faq-answer">{{ $t(`eresempresa.faqs.answer[${index}]`) }}</p>
      <hr v-if="index < questions.length - 1" class="guion">
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqEmpresa',
  data() {
    return {
      visibleAnswers: []
    };
  },
  computed: {
    questions() {
      // Obteniendo el total de preguntas para hacer el bucle en el template
      return this.$t('eresempresa.faqs.question');
    }
  },
  methods: {
    toggleAnswer(index) {
      if (this.visibleAnswers.includes(index)) {
        this.visibleAnswers = this.visibleAnswers.filter(i => i !== index);
      } else {
        this.visibleAnswers.push(index);
      }
    },
    isAnswerVisible(index) {
      return this.visibleAnswers.includes(index);
    }
  }
};
</script>

<style scoped>
/* Estilos para el componente FAQ */
.faq-title {
  margin-bottom: 3rem;
}
.faq-answer {
  font-size: 1.2rem;
  text-align: left;
}
.faq-item {
  margin-bottom: 20px;
  cursor: pointer;
}
.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq-question span{
  color: #ff00ff
}
.triangle-right::after {
  content: '\25B6'; /* Triángulo apuntando a la derecha */
}
.triangle-down::after {
  content: '\25BC'; /* Triángulo apuntando hacia abajo */
}
.guion {
  color: black;
  background-color: black;
}
</style>
