<template>
  <div id="carousel">
    <b-carousel
      v-model="slide"
      id="carousel-1"
      :interval="50000000"
      fade
      controls
      background="#ababab"
      style="text-shadow: 1px 1px 2px #333;"
    >
      <b-carousel-slide
        v-bind:caption-html="$t('carouselEmpresa.c1')"
        :img-src="require('../../assets/banner/foto1.webp')"
      />
      <b-carousel-slide
        v-bind:caption-html="$t('carouselEmpresa.c2')"
        :img-src="require('../../assets/banner/foto2.webp')"
        class="azul"
      />
      <b-carousel-slide
        v-bind:caption-html="$t('carouselEmpresa.c3')"
        :img-src="require('../../assets/banner/foto3.webp')"
      />
      <b-carousel-slide
        v-bind:caption-html="`<span style='color: white;'>${$t('carouselEmpresa.c4')}</span>`"
        :img-src="require('../../assets/banner/foto4.webp')"
      />
    </b-carousel>
  </div>
</template>

<style>


@media screen and (max-width: 425px) {
  #carousel{
    margin-top: 0px;
  }
}

#carousel .carousel-caption{
  bottom: 50%!important;
  transform: translateY(-75%);
  text-align: left!important;
  right: 0%!important;
  padding-top: 0%!important;
  padding-bottom: 0%!important;
}
#carousel .carousel-caption h3{
    text-align: left;
    color: #BAFF43;
    font-weight: 900;
    font-size: 1em;
}

#carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
  /* margin-bottom: 3%; */
}

@media screen and (min-width: 375px) {
  #carousel .carousel-caption h3{
    font-size: 1em;
  }
  #carousel .carousel-caption{
    bottom: 20%!important;
    text-align: left!important;
    transform: translateY(-45%);
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 10%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 10%;
  }
  #carousel .carousel-item:nth-child(5) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 425px) {
  #carousel .carousel-caption h3{
    font-size: 1.5em;
  }
  #carousel .carousel-caption{
    bottom: 25%!important;
    transform: translateY(-75%);
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(5) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }

  #carousel .carousel-item:nth-child(3) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(4) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
}

@media screen and (min-width: 768px) {
  #carousel .carousel-caption h3{
    font-size: 2em;
  }
  #carousel .carousel-caption{
    bottom: 50%!important;
    transform: translateY(-75%);
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(5) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }

  #carousel .carousel-item:nth-child(3) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(4) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
}

@media screen and (min-width: 1024px) {
  #carousel .carousel-caption h3{
    font-size: 2.5em;
  }
  #carousel .carousel-caption{
    bottom: 50%!important;
    transform: translateY(-75%);
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(5) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }

  #carousel .carousel-item:nth-child(3) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(4) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
}

@media screen and (min-width: 1300px) {
  #carousel .carousel-caption h3{
    font-size: 3em;
  }
  #carousel .carousel-caption{
    bottom: 50%!important;
    transform: translateY(-75%);
  }
  #carousel .carousel-item:nth-child(2) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(1) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(5) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }

  #carousel .carousel-item:nth-child(3) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
  #carousel .carousel-item:nth-child(4) h3{
  /* color: #0000FF; */
  /* right: 15%; */
    margin-bottom: 1%;
  }
}

</style>
<script>
import {mapMutations} from 'vuex'

export default {
  name: 'CarouselEmpresa',
  data(){
    return{
      slide: 0
    }
  },
  methods:{
    ...mapMutations(['navVariant']),
    goToPage() {
      window.open('https://cloud.edu.mindhubweb.com/openhouse', '_blank');
    },
    scrollToSection() {
      // Obtener el elemento objetivo
      const target = document.getElementById('capacitar');
      // Desplazarse al elemento objetivo
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
        // Ajustar el desplazamiento después de un pequeño retraso
        setTimeout(() => {
          window.scrollBy(0, 500); // Ajusta este valor según sea necesario
        }, 500); // Ajustar el tiempo de espera según sea necesario
      }
    }
  },
  watch:{
    slide(){
      if(this.slide % 2 == 0){
        this.navVariant('blue')
      }else{
        this.navVariant('pink')
      }
    }
  }
}
</script>