<template>
  <div class="my-5">
    <template v-if="mq.matches">
      <b-carousel :interval="2000" class="empresas-carousel">
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/01-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/03-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/12-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/04-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/05-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/06-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/08-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/09-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/10-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/07-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/14-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </template>
    <template v-else>
      <b-carousel :interval="2000" class="empresas-carousel">
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/01-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/03-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/04-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/05-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/06-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/07-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/08-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/09-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/10-1.png" alt="empresas" />
              </div>
              <div>
                <img src="@/assets/logos/12-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template slot="img">
            <div class="d-flex justify-content-around">
              <div>
                <img src="@/assets/logos/14-1.png" alt="empresas" />
              </div>
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </template>
  </div>
</template>

<script>
export default {
  name: "CarouselEmpresas",
  data(){
        return{
            mq: window.matchMedia('(min-width: 1350px)'),
            
        }
    },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .empresas-carousel img{
      width: 100px;
  }
}

@media screen and (max-width: 425px) {
  .empresas-carousel img{
      width: 50px;
  }
}
</style>